<style scoped>
	.file:hover{
		text-decoration: underline;
		color: blue;
	}
</style>
<template>
	<v-list-item :value="item">
		<v-list-item-icon>
			<v-menu offset-y>
				<template v-slot:activator="{ on }"> 
					<v-btn icon small> <v-icon color="grey" v-on="on">mdi-dots-horizontal</v-icon></v-btn>
				</template>
			</v-menu>
		</v-list-item-icon>
		<v-list-item-content>
			<v-list-item-title @click="downloadFile" class="file"><span class="font-weight-light">{{name}}</span></v-list-item-title>
		</v-list-item-content>
	</v-list-item>
</template>
<script>
	// import Vuex from "vuex";
	import {getFileFromStorage, deleteFileFromStorage} from "../../assets/storageFunctions.js";
	export default{
		name: 'FileListItem',
		props: {
			item: {type: Object },
		},
		data: () => {
			return {
				items: [],
			}
		},
		computed: {
			path(){
				return this.item.fullPath
			},
			name(){
				return this.item.name
			},
		},
		methods: {
			deleteFile(){
				const self = this;
				deleteFileFromStorage(self.path).then( () => {
					this.$emit( "deleted", self.item )
				})
			},
			downloadFile(){
				const self = this;
				getFileFromStorage(self.path).then( url  => {
					var a = document.createElement("a");
					a.setAttribute("href", url )
					a.click()
				} ) 
			}
		}
	}
</script>

